<template>
	<div>
		<div class="main">
			
		</div>
	</div>
</template>

<script>

export default {
	name: 'Summary',

	data: () => ({
		title: "Podsumowanie godzin"
	}),

	methods: {
		setTitle() {
			this.$emit('set-title', this.title)
		},
	},
	mounted() {
		this.setTitle(this.title)
	},
};
</script>

<style>

</style>
